
.header-search-wrapper.show .live-search-list {
	display: block;
}

.newsletter-popup-container {
	.banner-content {
		padding: 4.6rem 1rem 6.7rem;
		flex-basis: calc(80% + 16px);
		max-width: calc(80% + 16px);
	}
}

.live-search-list {
	right: -1.2rem;
    top: 112%;
    left: auto;
}

.intro-slider-container,
.intro-slide {
	height: 300px;
	background-color: #eee;
}

.intro-slider-container {
	.owl-nav {
		opacity: 0;
		visibility: hidden;
		transition: opacity .35s, visibility .35s;
	}

	&:hover{
		.owl-nav {
			opacity: 1;
			visibility: visible;
		}
	}
}

.intro-slide {
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center center;

	.intro-content {
		position: static;
		left: auto;
		top: auto;
		transform: translateY(0);
		-ms-transform: translateY(0);
	}

	.btn {
		font-size: 1.2rem;
		min-width: 9rem;
		padding: .65rem 1rem;
	}
}

.intro-subtitle {
	font-weight: 300;
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: 0;
	margin-bottom: 1rem;
}

.intro-title {
	color: #333333;
	font-size: 3rem;
	line-height: 1.2;
	font-weight: 300;
	text-indent: -.2rem;
	letter-spacing: -.025em;
	margin-bottom: 1rem;
}

.home-page {
	.product.product-4 .btn-product {
		text-transform: none;
	}

	.owl-simple .owl-nav [class*='owl-'] {
		font-size: 2.2rem;
		color: #777;
	}

	.owl-simple.owl-nav-inside .owl-nav {
		.owl-prev {
			left: 5%;
		}
		.owl-next {
			right: 5%;
		}
	}

	.owl-simple.owl-nav-inside .owl-dots {
		bottom: 20px;
	}

	.nav-filter a {
		padding: .3rem 1rem;
	}

	.filter-area-wrapper {
		padding-bottom: 2rem;
	}

	.filter-colors.filter-colors-vertical {
		padding-top: .3rem;
		flex-direction: column;
		align-items: flex-start;

		a {
			width: 2rem;
			height: 2rem;
			color: #666;
			margin-bottom: 1.1rem;

			> span {
				display: block;
				position: absolute;
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
				margin-left: 1rem;
			}

			&:hover,
			&:focus,
			&.selected {
				> span {
					color: $primary-color;
				}
			}
		}
	}

	.filter-price-text {
		margin-bottom: 2.2rem;
	}

	.products-container {
		position: relative;
		transition: height .4s;
		@include clearfix();

		.product-media img {
			filter: brightness(0.95);
		}
	}

	.loaded .products-container {
		margin: 0 -1rem;
	}

	.product-item {
		float: left;
		margin-bottom: 1rem;

		.product {
			margin-bottom: 0;
		}
	}

	.product-media {
		&::before {
			@include aspect-ratio(300, 300);
		}
	}

	.product {
		margin-bottom: 1rem;
		overflow: hidden;

		&:hover {
			box-shadow: none;
		}

		.product-body {
			padding-left: 0;
			padding-right: 0;
		}

		.product-price {
			margin-bottom: .3rem;
		}
	}

	.product-title {
		font-size: 1.4rem;
		letter-spacing: 0;
	}

	.product-price {
		font-size: 1.4rem;
		margin-bottom: .5rem;
	}

	.product-nav-dots {
		margin-left: 1px;
	}

	.old-price {
		text-decoration: none;
	}

	.product.product-4 {
		margin-bottom: 0;

		.product-action-vertical {
			top: 2rem;
		}

		.product-title {
			color: #666;
			margin-bottom: .3rem;
		}

		.product-body {
			background-color: transparent;

			.product-action {
				transform: translateY(0);
				bottom: .3rem;
			}

			.btn-product {
				display: inline-flex;
				color: $primary-color;
				background-color: transparent !important;
				justify-content: flex-start;
				flex: 0 0 auto;
				padding: .1rem 0;

				i {
					margin-left: .9rem;
				}

				span {
					font-size: 1.4rem;
				}

				&:hover,
				&:focus {
					color: $primary-color;
					background-color: transparent;
					box-shadow: 0 .1rem 0 0 $primary-color;
				}
			}
		}

		.btn-product {
			color: #666;
			padding-top: 1.35rem;
			padding-bottom: 1.35rem;

			&:before {
				display: none;
			}

			&:hover,
			&:focus {
				background-color: $primary-color;
			}
		}

		.btn-product:not(:hover):not(:focus) {
			background-color: rgba(255, 255, 255, .9);
		}

		.product-nav {
			margin-top: .5rem;
			margin-bottom: .5rem;
		}
	}

	.btn-more {
		letter-spacing: .01em;
		min-width: 170px;
		i {
			font-size: 1.5rem;
		}

		&:hover,
		&:focus {
			color: $primary-color;
			background-color: #fafafa;
			border-color: #d7d7d7;
		}
	}
}

.sidebar-fixed-toggler {
	display: none;
	top: 51vh;
}

.filter-toggler.mobile-mode {
	display: none;
}

.nav-filter {
	.toolbox & {
		margin-bottom: 0;
		transition: opacity .4s;
	}

	a {
		font-size: 1.6rem;
		text-transform: capitalize;
	}

	li + li {
		margin-left: .8rem;
	}
}

.filter-toggler {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: $dark-text;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 31px;
	letter-spacing: -.01em;
	margin-right: 3rem;
	padding-left: 2.4rem;

	i {
		margin-right: .8rem;
	}

	&:before {
		content: '\f131';
		display: block;
		position: absolute;
		left: -.2rem;
		top: 50%;
		margin-top: -1px;
		transform: translateY(-50%);
		font-family: 'molla';
		font-size: 1.9rem;
		line-height: 1;
	}

	&.active {
		&:before {
			content: '\f191';
		}
	}

	&:hover,
	&:focus {
		color: $primary-color;
	}
}

.widget-filter-area {
	display: block;
	position: relative;
	transition: all .3s;

	.widget {
		padding-top: 2rem;
		border-top: .1rem solid #ebebeb;
		margin-bottom: 0;
		padding-bottom: 3rem;
	}

	.widget-title {
		color: #333;
		font-weight: 300;
		font-size: 1.6rem;
		letter-spacing: -.01em;
		margin-bottom: 2.4rem;
	}

	.custom-control {
		margin-top: .4rem;
		margin-bottom: .4rem;
	}

	&.active{
		visibility: visible;
		transform: translateX(0);
	}

	&::-webkit-scrollbar {
        width: 7px;
        height: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ebebeb;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
        border-image: 10px;
        margin: 8px;
        width: 100%;
	}
}

.widget-filter-area {
	padding-top: .3rem;
}

.home-page {
	.display-none {
		display: none;
	}

	.transition-none {
		transition: none;
	}
}

.widget-filter-clear {
	display: block;
	color: $primary-color;
	position: absolute;
	right: 0;
	bottom: 100%;
	margin-bottom: 2.4rem;
	font-weight: 300;
	font-size: 1.6rem;
	line-height: 1.5;
}

.footer-menu a:hover,
.footer-menu a:focus {
	box-shadow: 0 1px 0 $primary-color;
}

.element-tab-page {
	.nav.nav-tabs .nav-item .nav-link.active, .nav.nav-tabs .nav-item.show .nav-link {
		border-color: #d7d7d7 #d7d7d7 #fff;
	}
}

@include mq('sm') {
	.intro-slider-container,
	.intro-slide {
		height: 400px;
	}
}

@include mq('md') {
	.intro-slider-container,
	.intro-slide {
		height: 450px;
	}

	.intro-subtitle {
		font-size: 1.4rem;
		margin-bottom: 1.2rem;
	}
}


@include mq('lg','max') {
	.widget-collapse {
		display: block !important;
	}

	.widget-filter-area {
		display: block !important;
		visibility: hidden;
		transform: translateX(-100%);
		position: fixed;
		top: 0;
		left: 0;
		max-width: 270px;
		width: 100%;
		background-color: white;
		z-index: 100000;
		padding: 10px;
		overflow: scroll;
		height: 100%;
		.row{
			display: block;
		}
	}

	.filter-toggler{
		display: none;
	}

	.filter-toggler.mobile-mode {
		display: block;
	}

	.nav-filter.product-filter {
		display: flex !important;
		opacity: 1 !important;
	}

	.my-collapsible__content {
		display: block !important;
	}

	.widget-filter-clear{
		left: 13rem;
		top: 1rem;
		position: relative;
	}

	.toolbox-right {
		min-height: 3rem;
	}

	.clean-close {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.widget-close {
			padding-bottom: 5px;

			.icon-close {
				font-size: 15px
			}
		}

		.widget-filter-clear {
			left: 0;
		}
	}
}

@include mq('md','max') {
	.filter-toggler.mobile-mode{
		display: none;
	}

	.sidebar-fixed-toggler {
		display: block;
	}

	.intro-slide .intro-content{
		padding-left: 10rem;
		padding-right: 10rem;
	}
}

@include mq('sm','max') {
	.sidebar-fixed-toggler {
		top: 46vh;
	}

	.intro-slide .intro-content{
		padding-left: 5rem;
		padding-right: 5rem;
	}
}

@include mq('xs','max') {
	.intro-slide .intro-content{
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

@include mq('380px', 'max') {
	.widget-filter-area {
		max-width: 270px;
	}
}

@include mq('lg') {
	.home-page {
		.intro-slider-container,
		.intro-slide {
			height: 480px;
		}
	}

	.intro-content {
		.btn {
			min-width: 170px;
		}

		.min-width-sm {
			min-width: 150px;
		}
	}

	.intro-subtitle {
		font-size: 1.6rem;
		margin-bottom: 1.5rem;
	}

	.intro-title {
		font-size: 4.6rem;
		margin-bottom: 1.7rem;
	}

	.sidebar-filter-overlay {
		display: none;
	}

	.widget-close {
		display: none;
	}
	.widget-filter-clear {
		display: block;
	}
}

@include mq('xl') {
	.home-page {
		.intro-slider-container,
		.intro-slide {
			height: 560px;
		}
	}

	.intro-content {
		.btn {
			min-width: 190px;
			font-size: 1.4rem;
			padding-top: 1.15rem;
			padding-bottom: 1.15rem;
		}
	}

	.intro-title {
		font-size: 5rem;
	}
}
