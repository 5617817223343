// Animations
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

@keyframes product-opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fixedFooter {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}

	100% {
		opacity: 1;
		transform:translateY(0);
	}
}

// opacity

.load-more-rotating {
    animation: 1s ease 0s normal both infinite rotating;
}

.load-more-opacity {
    animation: 2s ease 0s normal both infinite product-opacity;
}

.general-opacity {
    animation: .3s ease 0s normal both infinite product-opacity;
}

.appear-animate {
    opacity: 0;
    visibility: hidden;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform-origin: 50% 50%;
    }

    to {
        opacity: 1;
        transform-origin: 50% 50%;
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;

        transform-origin: 50% 50%;
    }

    to {
        opacity: 0;
        transform-origin: 50% 50%;
    }
}

@keyframes showMsg {
    0% {
        transform: translateX(100%)
    }

    100% {
        transform: translateX(-100%)
    }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}