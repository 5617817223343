/* Single Entry - Post */
.entry.single-entry {
	margin-bottom: 4rem;
}

.single-entry {
	.entry-body {
		margin-bottom: 4rem;
	}

	.entry-meta,
	.entry-cats {
		color: #ccc;
		a:not(:hover):not(:focus) {
			color: #666;
		}
	}

	.entry-title-big {
		font-weight: 400;
		font-size: 2.8rem;
	}
}

.editor-content {
	@include clearfix();
	.float-sm-left {
		margin-right: 2.8rem;
		margin-bottom: 1.5rem;
	}

	.float-right {
		margin-left: 2.8rem;
		margin-bottom: 1.5rem;
	}

	img {
		margin-bottom: 2rem;
	}

	p {
		margin-bottom: 2.4rem;
	}

	h3 {
		font-weight: 400;
		font-size: 2.2rem;
		margin-bottom: 2rem;
	}

	a {
		color: #333;
		box-shadow: 0 1px 0 #333;
		transition: all .35s ease;

		&:hover,
		&:focus {
			color: $primary-color;
			box-shadow: 0 1px 0 $primary-color;
		}
	}

	blockquote {
		position: relative;
		color: #333333;
		font-style: normal;
		font-weight: 400;
		font-size: 1.8rem;
		line-height: 1.67;
		padding: 7rem 3rem 4rem 3rem;
		border-left: .1rem solid $primary-color;
		background-color: #fafafa;
		margin-top: 4.2rem;
		margin-bottom: 4.2rem;

		p:last-child {
			margin-bottom: 0;
		}

		&:before {
			color: #ebebeb;
			content: '\f10e';
			font-weight: normal;
			font-size: 3rem;
			line-height: 1;
			font-family: 'molla';
			position: absolute;
			left: 3rem;
			top: 4rem;
			margin-top: -1.5rem;
		}
	}


	ul li {
		position: relative;
		margin-bottom: 2.7rem;

		&:before {
			display: inline-block;
			content: '•';
			font-size: 1.8rem;
			line-height: 1;
			margin-right: 1rem;
			vertical-align: middle;
			margin-top: -1px;		}
	}
}

.entry-footer {
	margin-top: 3.6rem;
	margin-bottom: 5rem;
	align-items: center;

	.social-icon {
		font-size: 1.3rem;
	}
}

.entry-tags {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	padding-top: .2rem;

	span {
		margin-right: 1rem;
	}

	a {
		display: block;
		color: #777;
		font-weight: 300;
		font-size: 1.4rem;
		line-height: 1.4;
		border-radius: .3rem;
		padding: .45rem .9rem;
		background-color: #fafafa;
		border: .1rem solid #ebebeb;
		margin-right: .5rem;
		margin-bottom: 0;
		transition: all .3s ease;

		&:hover,
		&:focus {
			color: $primary-color;
			background-color: #fff;
		}
	}
}

.entry-author-details {
	display: flex;
	align-items: flex-start;
	padding: 5rem 3rem 4.6rem;
	background-color: #fafafa;

	h4 {
		color: #333;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 1;
		letter-spacing: -.01em;
		margin-bottom: 0;

		a {
			color: inherit;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}

.author-media,
.comment-media {
	flex: 0 0 50px;
	max-width: 50px;

	img {
		border-radius: 50%;
	}
}

.author-content,
.comment-content {
	p:last-child {
		margin-bottom: 0;
	}
}

.author-body {
	position: relative;
	flex: 1 1 auto;
	padding-left: 2rem;
}

.author-header {
	align-items: flex-start;
	line-height: 1;
	margin-bottom: 2rem;
}

.author-link {
	color: #777;
	letter-spacing: -.01em;
	transition: all .35s ease;

	&:hover,
	&:focus {
		color: $primary-color;
		box-shadow: 0 1px 0 $primary-color;
	}
}

.pager-nav {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 4.5rem;
	padding-bottom: 1rem;
}

.pager-link {
	position: relative;
	color: $primary-color;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 0 0 100%;
	max-width: 100%;
	width: 100%;
	font-weight: 400;
	padding-top: .95rem;
	padding-bottom: .95rem;
	font-size: 1.4rem;
	line-height: 1.5;
	transition: all .35s ease;

	& + .pager-link {
		border-top: .1rem solid #ebebeb;
	}

	&:after {
		color: #333;
		display: block;
		font-family: 'molla';
		font-size: 1.5rem;
		line-height: 1;
		position: absolute;
		top: 50%;
		transition: all .35s ease .05s;
		margin-top: -.8rem;
	}

	&.pager-link-prev {
		padding-left: 10rem;
		padding-right: 1rem;

		&:after {
			content: '\f273';
			left: 3rem;
		}
	}

	&.pager-link-next {
		padding-left: 1rem;
		padding-right: 10rem;
		align-items: flex-end;
		text-align: right;

		&:after {
			content: '\f274';
			right: 3rem;
		}
	}

	&:hover {
		color: $primary-color;

		&:after {
			color: $primary-color;
		}


		&.pager-link-prev {
			padding-left: 7rem;

			&:after {
				left: 0;
			}
		}

		&.pager-link-next {
			padding-right: 7rem;

			&:after {
				right: 0;
			}
		}

		.pager-link-title {
			box-shadow: 0 .1rem 0 #333;
		}
	}
}

.pager-link-title {
	display: inline-block;
	margin-top: .6rem;
	flex: 0 0 auto;
	color: #333;
	font-size: 1.5rem;
	transition: all .35s ease;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.related-posts {
	padding-bottom: 4.5rem;
	margin-bottom: 4.5rem;
	border-bottom: .1rem solid #ebebeb;

	.title {
		font-weight: 600;
		font-size: 1.8rem;
		letter-spacing: -.025em;
		margin-bottom: 3rem;
	}

	.entry.entry-grid {
		margin-bottom: 0;
	}

	.entry-grid {
		.entry-title {
			font-weight: 400;
		}
	}
	.entry-media {
		img {
			height: 100%;
		}
	}
}

.comments {
	margin-bottom: 5rem;

	.title {
		font-weight: 600;
		font-size: 1.8rem;
		letter-spacing: -.025em;
		margin-bottom: 4.5rem;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		ul {
			margin-top: 4rem;
			margin-left: 8rem;
			padding-top: 4.5rem;
			border-top: .1rem solid #ebebeb;;
		}
	}

	li {
		border-bottom: .1rem solid #ebebeb;
		padding-bottom: 4rem;
		margin-bottom: 5rem;

		&:last-child {
			border-bottom-width: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.comment {
	position: relative;
	display: flex;
	align-items: flex-start;
}
.comment-body {
	position: relative;
	flex: 1 1 auto;
	padding-left: 3rem;
}

.comment-reply {
	display: flex;
	align-items: center;
	color: #777;
	position: absolute;
	right: 0;
	top: 0;
	font-weight: 300;
	font-size: 1.4rem;
	line-height: 1;
	letter-spacing: -.01em;
	padding-bottom: .4rem;
	transition: all .35s ease;

	&:after {
		font-family: 'molla';
		content: '\f279';
		font-size: 1.5rem;
		margin-left: .7rem;
	}

	&:hover,
	&:focus {
		color: $primary-color;
		box-shadow: 0 1px 0 $primary-color;
	}
}

.comment-user {
	margin-bottom: 1rem;
	h4 {
		color: #333;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 1;
		letter-spacing: -.01em;
		margin-bottom: 0;

		a {
			color: inherit;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}

		span {
			display: block;
		}
	}
}

.comment-date {
	color: #ccc;
	font-weight: 300;
	font-size: 1.3rem;
	line-height: 1;
}

.reply {
	padding: 2rem 0 0;

	.heading {
		margin-bottom: 2.4rem;
	}

	.title {
		font-weight: 600;
		font-size: 1.8rem;
		letter-spacing: -.025em;
		margin-bottom: .9rem;
	}

	.title-desc {
		color: #ccc;
		font-size: 1.3rem;
		letter-spacing: -.01em;
	}

	.form-control {
		background-color: #fff;
	}
	
	.custom-checkbox {
		margin-top: 7px;
	}

	textarea.form-control {
		min-height: 100px;
	}
}

// Rating Form (new)
.rating-form{
	display: flex;
	align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    label { margin-right: 1rem; }
}
.rating-stars {
	display: flex;
	position: relative;
	height: 14px;
	font-size: 1.4rem;
	margin: 0 0 1.1rem;

	a {
		color: #999;
		text-indent: -9999px;
		letter-spacing: 1px;
		width: 16px;
		letter-spacing: .2em;

		&:hover {
			color: #fcb941;
		}
	}

	a:before {
		content: '';
		position: absolute;
		left: 0;
		height: 14px;
		line-height: 1;
        font-family: 'molla';
		text-indent: 0;
		overflow: hidden;
		white-space: nowrap;
	}

	a.active:before,
	a:hover:before {
		content: "\f318\f318\f318\f318\f318";
        color: #fcb941;
	}

	.star-1 {
		z-index: 10;
	}

	.star-2 {
		z-index: 9;
	}

	.star-3 {
		z-index: 8;
	}

	.star-4 {
		z-index: 7;
	}

	.star-5 {
		z-index: 6;
	}

	.star-1:before {
		width: 20%;
	}

	.star-2:before {
		width: 40%;
	}

	.star-3:before {
		width: 60%;
	}

	.star-4:before {
		width: 80%;
	}

	.star-5:before {
		content: "\f318\f318\f318\f318\f318";
	}
}

.reply {
	padding: 2rem 0 0;

	.heading {
		margin-bottom: 2.4rem;
	}

	.title {
		font-weight: 600;
		font-size: 1.8rem;
		letter-spacing: -.025em;
		margin-bottom: .9rem;
	}

	.title-desc {
		color: #ccc;
		font-size: 1.3rem;
		letter-spacing: -.01em;
	}

	.form-control {
		background-color: #fff;
	}
	
	.custom-checkbox {
		margin-top: 7px;
	}

	textarea.form-control {
		min-height: 100px;
	}
}

/* Fullwidth */
.entry-media-fullwidth {
	img {
		max-height: 600px;
	}
}
.entry-media-sidebar {
	img {
		max-height: 500px;
	}
}
.entry-fullwidth {
	.entry-body {
		position: relative;

		@include mq('xl') {
			padding-left: 2rem;
		}
	}

	.author-body {
		padding-left: 4rem;
	}

	.social-icons.social-icons-vertical {
		margin-bottom: 2rem;
	}
}

@include mq('sm') {
	.pager-nav {
		flex-direction: row;
		padding-bottom: 3.5rem;
		border-bottom: .1rem solid #ebebeb;
	}

	.pager-link {
		flex: 0 0 50%;
		max-width: 50%;
		padding-top: .55rem;
		padding-bottom: .55rem;

		& + .pager-link {
			border-top: none;
			border-left: .1rem solid #ebebeb;
		}
	}
}

@include mq('md') {
	.single-entry {
		.entry-title-big {
			font-size: 3.4rem;
		}
	}

	.editor-content {
		@include clearfix();
		.float-sm-left {
			margin-right: 4.8rem;
			margin-bottom: 2rem;
		}

		.float-right {
			margin-left: 4.8rem;
			margin-bottom: 2rem;
		}

		h3 {
			font-size: 2.4rem;
		}

		blockquote {
			padding: 4rem 6rem 4rem 12rem;

			&:before {
				left: 4rem;
				top: 50%;
			}
		}
	}
}

@include mq('lg') {
	.single-entry {
		.entry-title-big {
			font-size: 4rem;
		}
	}

	.social-icons.social-icons-vertical {
		flex-direction: column;
		text-align: center;
		padding-bottom: 4rem;


		.entry-fullwidth & {
			margin-bottom: 0;
		}

		.social-label {
			margin-right: 0;
			margin-bottom: 1.1rem;
		}

		.social-icon {
			margin-bottom: 1rem;
			margin-right: 0;
		}

		.social-icon  + .social-icon {
			margin-left: 0;
		}
	}
}

@include mq('lg', 'max') {
	.sticky-content.is_stuck {
		position: static !important;;
		top: auto !important;;
		width: auto !important;;
		bottom: auto !important;
	}
}

@include mq('sm', 'max') {
	.editor-content img.float-sm-left {
		width: 100%;
	}
}

@include mq('xs', 'max') {
	.entry-author-details {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}