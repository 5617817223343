@import 'core';
@import 'animate';
@import 'autoheight';
@import 'lazyload';
@import 'video';

/* Owl Themes */
@import "theme.default";
@import "theme.simple";
@import 'theme.full';
@import 'theme.custom';